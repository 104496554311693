<template>
  <div class="container">
    <nav class="columns">
      <div class="column is-half is-offset-one-quarter">
        <nav class="level"></nav>
        Wyniki wyszukiwania dla miasta: {{ this.$route.params.cityName }}
        <div>
          <b-button type="is-text" size="is-medium" class="px-1" tag="router-link" :to="{name: 'ExploratorStartSearch'}">Wyszukaj jeszcze raz</b-button>
        </div>
      </div>
    </nav>

    <div v-if="doesCompaniesExists">
      <div class="columns is-multiline">
        <div
          class="column box is-half is-offset-one-quarter mb-3"
          v-for="(company, index) in companies"
          :key="index"
        >
          <company-details :company="company" isRouterLink></company-details>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CompanyDetails from "@/components/CompanyDetails";

export default {
  components: {
    "company-details": CompanyDetails,
  },
  
  methods: {
    companyAddressToString(address) {
      return `${address.street} ${address.localNb}, ${address.postcode} ${address.city}`;
    },

    showCompany(companyId) {
      this.$router.push({ name: "Company", params: { id: companyId } });
    },
  },

  computed: {
    companies() {
      return this.$store.getters["companyData/getCompanies"];
    },

    doesCompaniesExists() {
      return this.companies !== undefined && this.companies.length > 0;
    },
  },
};
</script>